/* eslint-disable */

import { combineReducers } from 'redux';
import { authModal } from './authModal.reducer';
import { feedbackModal } from './feedbackModal.reducer';
import { alert } from './alert.reducer';
import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { modalTrade } from './modal-trade.reducer';
import { currentChat } from './chat.reducer';
import { currentDashboard } from './dashboard.reducer';
import { currentFilter } from './filter.reducer';
import { currentCollapsePanel } from './collapsePanel.reducer';
import getUserInfo from './getUserInfo.reducer';
import getAllOrders from './getAllOrders.reducer';
import getAllCoins from './getAllCoins.reducer';
import refreshToken from './jwt.reducer';
import { getUserCountry } from './userCountry.reducer';
import { activeFeedback } from './statusFeedbackModal.reducer';
import getAllChats from './getAllChats.reducer';
import userLoginName from './email.reducer';
import socketConnected from './isSocketConnect.reducer';
import { getCurrentLocale } from './currentLocale.reducer';
import getAllInvoices from './getAllInvoices.reducer';

const rootReducer = combineReducers({
    authentication,
    registration,
    users,
    alert,
    authModal,
    feedbackModal,
    modalTrade,
    currentChat,
    currentDashboard,
    currentFilter,
    currentCollapsePanel,
    getUserInfo,
    getAllOrders,
    getAllCoins,
    refreshToken,
    getUserCountry,
    activeFeedback,
    getAllChats,
    userLoginName,
    socketConnected,
    getCurrentLocale,
    getAllInvoices,
});

export default rootReducer;
