import ordersConstants from '../constants/invoices.constants';

const getAllInvoices = (state, action) => {
    if (state === undefined) {
        return {
            orders: {},
            loading: true,
            error: null,
        };
    }
    switch (action.type) {
    case ordersConstants.FETCH_ALL_INVOICES_REQUEST:
        return {
            orders: {},
            loading: true,
            error: null,
        };

    case ordersConstants.FETCH_ALL_INVOICES_SUCCESS:
        return {
            orders: action.payload,
            loading: false,
            error: null,
        };

    case ordersConstants.FETCH_ALL_INVOICES_FAILURE:
        return {
            orders: {},
            loading: false,
            error: action.payload,
        };

    default:
        return state;
    }
};

export default getAllInvoices;
