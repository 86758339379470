import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    bool, func, object,
} from 'prop-types';
import TableRow from '../../../../small-components/cryptemper-table-row';
import EmptyFilterResult from '../../../../small-components/emptyFilterResult';
import PaginationComponent from '../../../../layouts/pagination';
import { personalAreaPath, newOrder } from '../../../../../constants';
import Spinner from '../../../../spinner';

import classes from './cryptemper-page.module.scss';
import 'rc-pagination/assets/index.css';
import GetServiceContext from '../../../../get-service-context/get-service-context';

const CryptemperView = ({
    orders,
    loading,
    refreshOrders,
}) => {

    const [isLoadingWallet, setLoading] = useState(false);
    const [wallet, setWallet] = useState(null);
    const { t } = useTranslation();

    const service = useContext(GetServiceContext);

    const fetchUserCoins = useCallback(async () => {
        try {
            setLoading(true);
            const newWallet = await service.getUserCoins();
            setWallet(newWallet);

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }


    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchUserCoins();
    }, [fetchUserCoins]);

    return (
        <div className={classes.p2pPage}>
            <div className={classes.orders}>
                <div className={classes.mobileNewOrder}>
                    <p className={classes.orders__title}>CRYPTEMPER</p>
                    <Link
                        to={`${personalAreaPath}${newOrder}`}
                        className={classes.mobileNewOrder_link}
                    >
                        {t('orders.new')}
                    </Link>
                </div>
                {loading ? (
                    <Spinner />
                ) : (
                    <div>
                        {orders.length >= 1 ? (
                <>
                  {orders.map(({
                      id,
                      createdAt,
                      description,
                      isCompleted,
                      usdPrice,
                  }) => (
                      <Fragment key={id}>
                          <TableRow
                              orderId={id}
                              createdAt={createdAt}
                              description={description}
                              isCompleted={isCompleted}
                              usdPrice={usdPrice}
                              wallet={wallet}
                              isLoadingWallet={isLoadingWallet}
                              refreshOrders={refreshOrders}
                          />
                      </Fragment>

                  ))}
                </>
                        ) : (
                            <EmptyFilterResult />
                        )}
                    </div>
                )}
            </div>

            {orders.totalCount > 10 ? (
                <PaginationComponent totalCount={orders.totalCount} />
            ) : null}
        </div>
    );
};

CryptemperView.defaultProps = {
    orders: {},
    loading: true,
};

CryptemperView.propTypes = {
    orders: object,
    loading: bool,
    refreshOrders: func.isRequired,
};

export default CryptemperView;
