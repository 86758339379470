import React, { Fragment, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import { withRouter } from 'react-router-dom';
import { compose } from '../../../utils';
import Button from '../button';
import SubscriptionModal from '../subscription-modal';
import classes from './cryptemper-table-row.module.scss';

const CryptemperTableRow = ({
    usdPrice,
    description,
    createdAt,
    isCompleted,
    orderId,
    userInfo: { id: currentUserId },
    wallet,
    isLoadingWallet,
    refreshOrders,
}) => {
    const [visible, setVisible] = useState(false);
    const { t } = useTranslation();

    const handleOpenInvoicePopup = () => setVisible(!visible);

    const buttonExchange = (
        <div className={classes.tableRowWrapper__currencyRatioWrapper}>
            <Button
                className={classes.tableRowWrapper__buttonWrapper_buttonExchange}
                type="button"
                onClick={handleOpenInvoicePopup}
            >
            Pay invoice
            </Button>
        </div>
    );

    return (
        <div className={classes.tableRowWrapper}>
            <div className={classes.tableRowWrapper__currencyRatioWrapper}>
                <Tooltip
                    placement="topLeft"
                    title={t('general.date')}
                >
                    <p className={classes.tableRowWrapper__currencyRatioWrapper_value}>
                        {createdAt}
                    </p>
                </Tooltip>
            </div>
            <div className={classes.tableRowWrapper__currencyRatioWrapper}>
                <Tooltip
                    placement="topLeft"
                    title={t('general.currency')}
                >
                    <p className={classes.tableRowWrapper__currencyRatioWrapper_value}>
                        USD
                    </p>
                </Tooltip>
            </div>
            <div className={classes.tableRowWrapper__currencyRatioWrapper}>
                <p className={classes.tableRowWrapper__currencyRatioWrapper_title}>
                    {t('general.amount')}
                </p>
                <Tooltip
                    placement="topLeft"
                    title={t('general.amount')}
                >
                    <p className={classes.tableRowWrapper__currencyRatioWrapper_value}>
                        {usdPrice}
                    </p>
                </Tooltip>
            </div>

            <div className={classes.tableRowWrapper__currencyRatioWrapper}>
                <p className={classes.tableRowWrapper__currencyRatioWrapper_title}>
                    {t('general.description')}
                </p>
                <Tooltip
                    placement="topLeft"
                    title={t('general.description')}
                >
                    <p className={classes.tableRowWrapper__currencyRatioWrapper_value}>
                        {description}
                    </p>
                </Tooltip>
            </div>
            {isCompleted ? (
                <div className={classes.tableRowWrapper__statusWrapper}>
                    <p className={classes.tableRowWrapper__statusWrapper_statusLabel}>
                        {t('general.status')}
                    </p>
                    Paid
                </div>
            ) : (
                <Fragment>
                    {currentUserId !== true ? buttonExchange : (
                        <div className={classes.tableRowWrapper__yourOrderWrapper}>
                            <p className={classes.tableRowWrapper__yourOrderWrapper_button}>
                                {t('general.yourOrder')}
                            </p>
                        </div>
                    )}
                </Fragment>
            )}
            <SubscriptionModal
                key={orderId}
                onChangeVisibility={handleOpenInvoicePopup}
                orderId={orderId}
                orderDescription={description}
                orderUsdPrice={usdPrice}
                visibleModal={visible}
                usdPrice={usdPrice}
                wallet={wallet}
                refreshOrders={refreshOrders}
                isLoadingWallet={isLoadingWallet}
            />
        </div>
    );
};

CryptemperTableRow.defaultProps = {
    usdPrice: '',
    description: '',
    createdAt: '',
    isCompleted: false,
    userInfo: {},
    isLoadingWallet: false,
};

CryptemperTableRow.propTypes = {
    orderId: string.isRequired,
    wallet: object.isRequired,
    usdPrice: string,
    description: string,
    createdAt: string,
    isCompleted: bool,
    userInfo: object,
    isLoadingWallet: bool,
    refreshOrders: func.isRequired,
};

const mapStateToProps = state => {
    const {
        getUserInfo: { userInfo },
        getAllCoins: { allCoins },
    } = state;

    return {
        userInfo,
        allCoins,
    };
};


export default compose(connect(mapStateToProps), withRouter)(CryptemperTableRow);
