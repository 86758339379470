import axios from 'axios';
import { authHeader } from '../../../helpers';


export const buyCoins = async (id, userCoinId) => axios.post(`${process.env.REACT_APP_API_URL}/api/cryptemper/pay/${id}/${userCoinId}`, {}, {
    headers: authHeader(),
});

export const getCoinRate = async coinName => axios.get(`${process.env.REACT_APP_API_URL}/api/coin-rate/${coinName}/USD`, {
    headers: authHeader(),
});
