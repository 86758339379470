/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component, Fragment } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Drawer } from 'antd';

import withGetService from '../../hoc';
import SelectLanguage from '../../language';
import UserComponent from './layouts/user-component';
import Button from '../../small-components/button';
import { compose } from '../../../utils';
import Login from '../../auth/login';
import { authModalActions, userActions } from '../../../actions';
import getAllCoinsAction from '../../../actions/getAllCoins.actions';
import Registration from '../../auth/registration/registration';
import {
    ordersPath,
    contactPath,
    faqPath,
    loginPath,
    registartionPath,
} from '../../../constants';

import Img from '../../small-components/img';
import logo from '../../assets/images/logo.svg';
import style from './header.module.scss';
import './header.scss';

export class HeaderSite extends Component {
    mounted = true;

    state = {
        showNotificationState: false,
        dropDownMenuState: false,
        mobileMenu: false,
    };

    componentDidMount() {
        setTimeout(() => {
            document.body.classList.add('domLoaded');
        }, 100);
    }

    componentDidUpdate(prevProps) {
        const { locale, getAllCoins } = this.props;
        if (locale !== prevProps.locale) {
            getAllCoins();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    showNotification = () => {
        if (this.mounted) {
            const { showNotificationState } = this.state;
            this.setState({
                showNotificationState: !showNotificationState,
            });
        }
    };

    showdropDownMenu = () => {
        if (this.mounted) {
            const { dropDownMenuState } = this.state;
            this.setState({
                dropDownMenuState: !dropDownMenuState,
            });
        }
    };

    closeNotification = () => {
        if (this.mounted) {
            this.setState({
                showNotificationState: false,
            });
        }
    };

    closeDropdown = () => {
        if (this.mounted) {
            this.setState({
                dropDownMenuState: false,
            });
        }
    };

    // show mobile menu
    showDrawer = () => {
        if (this.mounted) {
            const { mobileMenu } = this.state;
            this.setState({
                mobileMenu: !mobileMenu,
            });
        }

        const button = document.getElementById('humburgerMenu');
        button.classList.toggle('active');
    };

    closeModal = () => {
        const {
            closeModal,
            history,
            location: { pathname },
        } = this.props;
        if (pathname === loginPath || pathname === registartionPath) {
            history.push('/');
        }
        closeModal();
    };

    render() {
        const {
            t,
            visibleLogin,
            login,
            signUp,
            loggedIn,
            openLogin = () => {},
            openSignUp = () => {},
            logOut = () => {},
        } = this.props;

        const { mobileMenu } = this.state;

        const activeStyle = {
            color: '#B2947B',
            fontWeight: '700',
            borderBottom: '0.14vw solid #B2947B',
        };

        if (visibleLogin) {
            document.querySelectorAll('.filter').forEach(item => {
                item.style.filter = 'grayscale(100%)';
            });
            document.documentElement.style.overflowY = 'hidden';
        } else {
            document.querySelectorAll('.filter').forEach(item => {
                item.style.filter = 'none';
            });
            document.documentElement.style.overflowY = 'inherit';
        }

        let drawerWidth;
        let drawerPaddingTop;
        if (window.innerWidth < 500) {
            drawerWidth = '100vw';
            drawerPaddingTop = '35vw';
        } else if (window.innerWidth > 499 && window.innerWidth < 1050) {
            drawerWidth = '50vw';
            drawerPaddingTop = '10vw';
        }

        const mobileMenuBody = {
            backgroundColor: '#FAFBFF',
            padding: `${drawerPaddingTop} 0 0 0`,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: 'inherit',
        };

        return (
            <Fragment>
                <div className="filter">
                    <header className={style.header}>
                        <div className={style.header__leftSide}>
                            <div className="animationLogo">
                                <Link to="/" className={style.header__logo}>
                                    <Img src={logo} alt="logo" />
                                </Link>
                            </div>

                            <nav className={style.header__nav}>
                                <div className="animationNav">
                                    <ul className={style.header__nav_list}>
                                        <li>
                                            <NavLink
                                                to="/"
                                                activeStyle={activeStyle}
                                                exact
                                            >
                                                {t('header.main')}
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink
                                                to={ordersPath}
                                                activeStyle={activeStyle}
                                            >
                                                {t('header.orders')}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <a
                                                href="https://cards.crocobit.net/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {t('header.cards')}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://cryptemper.skysoft.company"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Cryptemper
                                            </a>
                                        </li>
                                        <li>
                                            <NavLink
                                                to={faqPath}
                                                activeStyle={activeStyle}
                                            >
                                                {t('header.faq')}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to={contactPath}
                                                activeStyle={activeStyle}
                                            >
                                                {t('header.contact')}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <a
                                                href="https://extension.crocobit.net/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {t('header.extension')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className={style.header__rightSide}>
                            {loggedIn ? (
                                <UserComponent />
                            ) : (
                                <div className="animationButtons">
                                    <Button
                                        onClick={() => openLogin()}
                                        type="button"
                                        className={style.header__login}
                                    >
                                        {t('auth.login')}
                                    </Button>
                                    <Button
                                        onClick={() => openSignUp()}
                                        type="button"
                                        className={style.header__signUp}
                                    >
                                        {t('auth.signUp')}
                                    </Button>
                                </div>
                            )}
                            <div className="animationSelectLang">
                                <SelectLanguage />
                                <div className={style.header__mobileMenu}>
                                    <svg
                                        className="ham hamRotate ham8"
                                        id="humburgerMenu"
                                        viewBox="0 0 100 100"
                                        onClick={this.showDrawer}
                                    >
                                        <path
                                            className="line top"
                                            d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
                                        />
                                        <path className="line middle" d="m 30,50 h 40" />
                                        <path
                                            className="line bottom"
                                            d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
                                        />
                                    </svg>
                                    <Drawer
                                        placement="right"
                                        closable={false}
                                        onClose={this.showDrawer}
                                        visible={mobileMenu}
                                        bodyStyle={mobileMenuBody}
                                        height="100vh"
                                        width={drawerWidth}
                                    >
                                        {loggedIn ? (
                                            <div className={style.mobileUserComponent}>
                                                <UserComponent />
                                            </div>
                                        ) : null}
                                        <ul
                                            className={style.header__mobileMenu_links}
                                            onClick={this.showDrawer}
                                        >
                                            <li>
                                                <NavLink
                                                    to="/"
                                                    activeStyle={activeStyle}
                                                    exact
                                                >
                                                    {t('header.main')}
                                                </NavLink>
                                            </li>

                                            <li>
                                                <NavLink
                                                    to={ordersPath}
                                                    activeStyle={activeStyle}
                                                >
                                                    {t('header.orders')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://cards.crocobit.net/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {t('header.cards')}
                                                </a>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to={faqPath}
                                                    activeStyle={activeStyle}
                                                >
                                                    {t('header.faq')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to={contactPath}
                                                    activeStyle={activeStyle}
                                                >
                                                    {t('header.contact')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://extension.crocobit.net/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {t('header.extension')}
                                                </a>
                                            </li>
                                            {loggedIn ? (
                                                <li>
                                                    <NavLink
                                                        to="./"
                                                        activeStyle={activeStyle}
                                                        onClick={() => logOut()}
                                                    >
                                                        {t('general.logout')}
                                                    </NavLink>
                                                </li>
                                            ) : null}
                                        </ul>

                                        {loggedIn ? null : (
                                            <Fragment>
                                                <Button
                                                    onClick={() => openLogin()}
                                                    type="button"
                                                    className={
                                                        style.header__mobileMenu_login
                                                    }
                                                >
                                                    {t('auth.login')}
                                                </Button>
                                                <Button
                                                    onClick={() => openSignUp()}
                                                    type="button"
                                                    className={
                                                        style.header__mobileMenu_signUp
                                                    }
                                                >
                                                    {t('auth.signUp')}
                                                </Button>
                                            </Fragment>
                                        )}

                                        <div className={style.header__mobileMenu_footer}>
                                            <p
                                                className={
                                                    style.header__mobileMenu_copyright
                                                }
                                            >
                                                Copyright
                                                <span
                                                    className={
                                                        style.header__mobileMenu_symbol
                                                    }
                                                >
                                                    &#169;
                                                </span>
                                                <span
                                                    className={
                                                        style.header__mobileMenu_p2p
                                                    }
                                                >
                                                    CROCOBIT
                                                </span>
                                            </p>
                                            <div className="mobileMenu__selectLanguage">
                                                <SelectLanguage />
                                            </div>
                                        </div>
                                    </Drawer>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
                {login ? (
                    <Modal
                        isOpen={visibleLogin}
                        onRequestClose={this.closeModal}
                        ariaHideApp={false}
                        className="reactModal"
                        contentLabel="Example Modal"
                        overlayClassName="modalOverlay"
                    >
                        <Fragment>{login ? <Login /> : <Registration />}</Fragment>
                    </Modal>
                ) : null}
                {signUp ? (
                    <Modal
                        isOpen={visibleLogin}
                        onRequestClose={this.closeModal}
                        ariaHideApp={false}
                        className="modalRegistr"
                        contentLabel="Example Modal"
                        overlayClassName="modalOverlay"
                    >
                        <Fragment>{<Registration />}</Fragment>
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

HeaderSite.defaultProps = {
    t: () => {},
    openLogin: () => {},
    openSignUp: () => {},
    closeModal: () => {},
    logOut: () => {},
    getAllCoins: () => {},
    history: {},
    visibleLogin: false,
    login: false,
    signUp: false,
    loggedIn: false,
    location: {},
    locale: {},
};

HeaderSite.propTypes = {
    t: PropTypes.func,
    openLogin: PropTypes.func,
    openSignUp: PropTypes.func,
    closeModal: PropTypes.func,
    getAllCoins: PropTypes.func,
    logOut: PropTypes.func,
    history: PropTypes.object,
    visibleLogin: PropTypes.bool,
    login: PropTypes.bool,
    signUp: PropTypes.bool,
    loggedIn: PropTypes.bool,
    location: PropTypes.object,
    locale: PropTypes.any,
};

const mapStateToProps = state => {
    const {
        authModal: { visibleLogin, login, signUp },
        authentication: { loggedIn },
        getCurrentLocale: { locale },
    } = state;

    return {
        visibleLogin,
        login,
        signUp,
        loggedIn,
        locale,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getAllCoins: getAllCoinsAction(getService),
        closeModal: () => authModalActions.closeModal(),
        openSignUp: () => authModalActions.openSignUp(),
        openLogin: () => authModalActions.openLogin(),
        logOut: () => userActions.logout(),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(HeaderSite);
