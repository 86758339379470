import { Select, Spin } from 'antd';
import {
    bool, func, number, object, string,
} from 'prop-types';
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import close from '../../assets/images/close.svg';
import Button from '../button';
import Field from '../field';
import style from './subscription-modal.module.scss';
import './subscription-modal.scss';
import { buyCoins, getCoinRate } from './SubscriptionModal.api';


const { Option } = Select;

const SubscriptionModal = ({
    visibleModal,
    onChangeVisibility,
    orderId,
    orderDescription,
    orderUsdPrice,
    wallet,
    refreshOrders,
    isLoadingWallet,
}) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [coinPrice, setCoinPrice] = useState(null);
    const [selectCoin, setCoin] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        const { documentElement } = document;

        if (visibleModal && documentElement.style.overflowY === 'hidden') {
            return;
        }

        if (!visibleModal && documentElement.style.overflowY === 'inherit') {
            return;
        }

        if (visibleModal) {
            documentElement.style.overflowY = 'hidden';
        } else {
            documentElement.style.overflowY = 'inherit';
        }
    }, [visibleModal]);


    const getSelectedCoin = useCallback(value => (wallet ? wallet.find(({ coin }) => coin.name === value) : null), [wallet]);

    const handleChangeCoin = async value => {
        if (selectCoin && value === selectCoin?.coin.value) {
            return;
        }

        const newSelectCoin = getSelectedCoin(value);


        try {
            const response = newSelectCoin ? await getCoinRate(newSelectCoin.coin.handle) : null;


            if (!response || response.status !== 200) {
                console.error(response.statusText);
                throw new Error('bad convert price');
            }

            setCoinPrice(parseFloat(response.data));
        } catch (error) {
            const { error: stringErrorMessage = '' } = error.response.data || {};

            setErrorMessage(stringErrorMessage || error.message);
        } finally {
            setCoin(newSelectCoin);
        }
    };

    const handleBuyCoin = async () => {
        if (errorMessage) {
            setErrorMessage('');
        }

        if (!selectCoin) {
            return;
        }

        try {
            const result = await buyCoins(orderId, selectCoin.id);
            const { data } = result;

            if (!data.success) {
                throw new Error('Try to buy coins not success');
            }

            if (refreshOrders) {
                await refreshOrders();
            }

            if (onChangeVisibility) onChangeVisibility();

            if (data.returnUrl) {
                window.open(data.returnUrl, '_blank');
            }
        } catch (err) {
            const { data = null } = err?.response || {};

            setErrorMessage(data?.error || err.message);
        }
    };

    const amountPrice = useMemo(() => (coinPrice && coinPrice !== 0 ? orderUsdPrice / coinPrice : null), [coinPrice, orderUsdPrice]);

    const { amount = null, coin = null } = selectCoin || {};

    const availableBalance = amount && coin
        ? `Available balance: ${amount} ${coin?.handle}`
        : <br />;

    return (
        <Modal
            isOpen={visibleModal}
            onRequestClose={onChangeVisibility}
            ariaHideApp={false}
            className="subscriptionModal reactModal"
            contentLabel="Example Modal"
            overlayClassName="modalOverlay"
        >
            <div className={style.subscriptionModal}>
                <h2 className={style.subscriptionModal__title}>Subscription payment</h2>
                <div onClick={onChangeVisibility} className={style.subscriptionModal__close}>
                    <img src={close} alt="close" />
                </div>
                <div className={style.subscriptionModal__content}>
                    <div className={style.subscriptionModal__info}>
                        <div className={style.subscriptionModal__info_item}>
                            <div className={style.subscriptionModal__info_item__caption}>
                                <span>Invoice</span>
                            </div>
                            <div className={style.subscriptionModal__info_item__value}>
                                <span># {orderId} {orderDescription}</span>
                            </div>
                        </div>
                        <div className={style.subscriptionModal__info_item}>
                            <div className={style.subscriptionModal__info_item__caption}>
                                <span>Acces period</span>
                            </div>
                            <div className={style.subscriptionModal__info_item__value}>
                                <span>1 month</span>
                            </div>
                        </div>
                        <div className={style.subscriptionModal__info_item}>
                            <div className={style.subscriptionModal__info_item__caption}>
                                <span>Price</span>
                            </div>
                            <div className={style.subscriptionModal__info_item__value}>
                                <span>$ {orderUsdPrice}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <form action="#" className={style.subscriptionModal__form}>
                    {errorMessage && (
                        <span className={style.error}>{errorMessage}</span>
                    )}
                    <div className={style.subscriptionModal__input_wrapper}>
                        <div className={style.subscriptionModal__input_label}>
                            <span>Choose the currency</span>
                        </div>
                        <Select
                            className="filterBlock-select"
                            placeholder={t('general.choose')}
                            onChange={handleChangeCoin}
                            value={selectCoin?.coin.name}
                            dropdownRender={menu => (isLoadingWallet ? (
                                <div className={style.dropdownSpiner}>
                                    <Spin size="small" />
                                </div>
                            ) : menu)}
                        >
                            {wallet ? wallet.map(({ id, coin: coinValue }) => (
                                <Option
                                    key={id}
                                    name="coin"
                                    value={coinValue.name}
                                >
                                    {coinValue.name}
                                </Option>
                            )) : null}
                        </Select>
                    </div>
                    <div className={style.subscriptionModal__input_wrapper}>
                        <div className={style.subscriptionModal__input_label}>
                            <span>Price</span>
                        </div>
                        <Field
                            id="amount"
                            type="text"
                            placeholder="Amount"
                            name="amount"
                            value={amountPrice}
                            className={style.subscriptionModal__field}
                            inputStyle="rent__wrapper_input"
                        />
                        <p className={style.subscriptionModal__fieldTicker}>
                            {selectCoin?.coin.handle}
                        </p>
                    </div>
                    <div className={style.subscriptionModal___available_balance}>
                        {availableBalance}
                    </div>
                    <div className={style.subscriptionModal__buttonWrapper}>
                        <Button
                            disabled={!selectCoin || isLoadingWallet}
                            id="buySubscription"
                            type="button"
                            onClick={handleBuyCoin}
                            className={style.buySubscription}
                        >
                            Buy
                        </Button>
                    </div>
                </form>

            </div>
        </Modal>
    );
};

SubscriptionModal.defaultProps = {
    visibleModal: false,
    orderDescription: '',
    wallet: null,
    isLoadingWallet: false,
};

SubscriptionModal.propTypes = {
    visibleModal: bool,
    onChangeVisibility: func.isRequired,
    orderId: string.isRequired,
    orderDescription: string,
    orderUsdPrice: number.isRequired,
    wallet: object,
    refreshOrders: func.isRequired,
    isLoadingWallet: bool,
};

export default SubscriptionModal;
