import React from 'react';

const GetServiceContext = React.createContext();

const {
    Provider: GetServiceProvider,
    Consumer: GetServiceConsumer,
} = GetServiceContext;

export {
    GetServiceProvider,
    GetServiceConsumer,
};

export default GetServiceContext;
