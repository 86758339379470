import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {
    Select, Icon, Tooltip, Spin,
} from 'antd';
import QRCode from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classNames from 'classnames';
import { EDC, SFC } from '../../../../../constants';

import Img from '../../../../small-components/img';
import Button from '../../../../small-components/button';
import Field from '../../../../small-components/field';
import close from '../../../../assets/images/close.svg';
import copyContent from '../../../../assets/images/copy-content.svg';
import bitcoin from '../../../../assets/images/coins/bitcoin.svg';
import dash from '../../../../assets/images/coins/dash.svg';
import edc from '../../../../assets/images/coins/edc.png';
import ethereum from '../../../../assets/images/coins/ethereum.svg';
import litecoin from '../../../../assets/images/coins/litecoin.svg';
import zcash from '../../../../assets/images/coins/zcash.svg';
import EDC20 from '../../../../assets/images/coins/cde.svg';
import sfc from '../../../../assets/images/coins/sfc.svg';
import croco from '../../../../assets/images/coins/croco.svg';
import bnb from '../../../../assets/images/coins/bnb.png';

import style from './wallet-page.module.scss';
import './wallet-page.scss';
import 'rc-pagination/assets/index.css';

const WalletView = ({
    modalLoading,
    wallet,
    allCoins,
    getDeposit,
    openModalState,
    isShowFiatDepositModal,
    isShowFiatWithdrawalModal,
    depositModal,
    spinNewAdress,
    selectedDepositCoin,
    selectedWithDrawAmomount,
    withDrawCoin,
    fiatCoin,
    amount: amountState,
    toAddress,
    amountError,
    toAddressError,
    getNewAdress,
    filterCurrentCoinData,
    totalBalanceRef,
    totalBalanceTitleRef,
    availableBalanceBalanceRef,
    availableBalanceBalanceTitleRef,
    isDisabledWithdrawSubmitBtn,
    disabledSubmitBtn,
    successfulCopied = () => {},
    selectedWithDrawCoin = () => {},
    withdrawOnSubmit = () => {},
    chooseCurrency = () => {},
    chooseWithDrawCoin = () => {},
    withdrawInputOnchange = () => {},
    generateNewAdress = () => {},
    closeModal = () => {},
    trade = () => {},
    submitFiatWithdraw,
    inputOnChange,
    fiatAmount,
    totalPriceInFiat,
    selectOnChange,
    submitFiatDeposit,
    fiatCoinError,
    fiatAmountError,
    cryptoCoinHandle,
    cardNumber,
    cardNumberError,
    filteredFiatCoin,
    cryptoLimits,
}) => {
    const { t } = useTranslation();
    const { Option } = Select;

    const availableBalanceStyle = classNames(
        style.modal__inputWrapper_input,
        style.modal__inputWrapper_inputDisabled,
    );

    const fiatAmountInputStyle = classNames(
        style.modal__inputWrapper_input,
        style.modal__inputWrapper_inputFiatAmount,
    );

    const minimumWithdrawalAmount = filterCurrentCoinData.coin
        && filterCurrentCoinData.coin.commission.minimumWithdrawValue;

    const modalStyle = {
        opacity: '0',
        pointerEvents: 'none',
        userSelect: 'none',
        overflow: 'hidden',
        transition: 'all 0.25s',
    };

    const loadingStyle = modalLoading ? modalStyle : null;

    let modalContainerStyle = '';

    if (modalLoading) {
        modalContainerStyle = 'tradeModalWindow reactModal depositModalWindow overflowHidden';
    } else {
        modalContainerStyle = 'tradeModalWindow reactModal depositModalWindow';
    }

    const isEDCorSFC = [EDC, SFC];

    const showFiatDepositForThisCoin = [];
    cryptoLimits.map(item => showFiatDepositForThisCoin.push(item.coin));

    return (
        <div className={style.walletWrapper}>
            <div className={style.wallet}>
                <p className={style.wallet__title}>{t('general.wallet')}</p>

                {wallet.map(item => {
                    const {
                        id, coin, amount, amountOnOrders, frozenAmount,
                    } = item;

                    let walletLogo = '';
                    if (coin.handle === 'ETH') {
                        walletLogo = ethereum;
                    } else if (coin.handle === 'BTC') {
                        walletLogo = bitcoin;
                    } else if (coin.handle === 'DASH') {
                        walletLogo = dash;
                    } else if (coin.handle === EDC) {
                        walletLogo = edc;
                    } else if (coin.handle === 'LTC') {
                        walletLogo = litecoin;
                    } else if (coin.handle === 'ZEC') {
                        walletLogo = zcash;
                    } else if (coin.handle === 'EDC20') {
                        walletLogo = EDC20;
                    } else if (coin.handle === 'SFC') {
                        walletLogo = sfc;
                    } else if (coin.handle === 'CROCO') {
                        walletLogo = croco;
                    } else if (coin.handle === 'BNB') {
                        walletLogo = bnb;
                    } else {
                        walletLogo = null;
                    }

                    const isHideFiatDepositAndWithdrawal = showFiatDepositForThisCoin.includes(coin.handle);

                    return (
                        <Fragment key={id}>
                            <div className={style.walletTable}>
                                <div className={style.walletTable__logoWrapper}>
                                    <Img
                                        className={style.walletTable__logo}
                                        src={walletLogo}
                                        alt="logo"
                                    />
                                </div>

                                <div className={style.walletTable__coin}>
                                    <p className={style.walletTable__label}>
                                        {t('balances.coin')}
                                    </p>
                                    <p className={style.walletTable__coin_value}>
                                        {coin.handle}
                                    </p>
                                </div>
                                <div className={style.walletTable__fullName}>
                                    <p className={style.walletTable__label}>
                                        {t('balances.fullName')}
                                    </p>
                                    <p className={style.walletTable__fullName_value}>
                                        {coin.name}
                                    </p>
                                </div>
                                <div className={style.walletTable__totalBalance}>
                                    <p
                                        ref={totalBalanceTitleRef}
                                        className={style.walletTable__label}
                                    >
                                        {t('balances.totalBalance')}
                                    </p>
                                    <Tooltip
                                        placement="topLeft"
                                        title={amount + amountOnOrders + frozenAmount}
                                    >
                                        <p
                                            ref={totalBalanceRef}
                                            className={
                                                style.walletTable__totalBalance_value
                                            }
                                        >
                                            {amount + amountOnOrders + frozenAmount}
                                        </p>
                                    </Tooltip>
                                </div>
                                <div className={style.walletTable__availableBalance}>
                                    <p
                                        className={style.walletTable__label}
                                        ref={availableBalanceBalanceTitleRef}
                                    >
                                        {t('balances.availableBalance')}
                                    </p>
                                    <Tooltip placement="topLeft" title={amount}>
                                        <p
                                            ref={availableBalanceBalanceRef}
                                            className={
                                                style.walletTable__availableBalance_value
                                            }
                                        >
                                            {amount}
                                        </p>
                                    </Tooltip>
                                </div>
                                <div className={style.walletTable__inOrders}>
                                    <p className={style.walletTable__label}>
                                        {t('balances.inOrders')}
                                    </p>
                                    <Tooltip placement="topLeft" title={amountOnOrders}>
                                        <p className={style.walletTable__inOrders_value}>
                                            {amountOnOrders}
                                        </p>
                                    </Tooltip>
                                </div>
                                <div className={style.walletTable__inOrders}>
                                    <p className={style.walletTable__label}>
                                        Frozen balance
                                    </p>
                                    <Tooltip placement="topLeft" title={frozenAmount}>
                                        <p className={style.walletTable__inOrders_value}>
                                            {frozenAmount}
                                        </p>
                                    </Tooltip>
                                </div>
                                <div className={style.walletTable__buttonsWrapper}>
                                    <Select
                                        className="deposit-select"
                                        value={t('balances.deposit')}
                                        onChange={(value, name) => selectOnChange(
                                            value,
                                            name,
                                            coin.handle,
                                        )
                                        }
                                    >
                                        <Option name="CRYPTO_DEPOSIT" value="CRYPTO_DEPOSIT">
                                            Crypto
                                        </Option>
                                        {isHideFiatDepositAndWithdrawal
                                            ? (
                                                <Option name="FIAT_DEPOSIT" value="FIAT_DEPOSIT">
                                                    Fiat
                                                </Option>
                                            )
                                            : null}
                                    </Select>

                                    <Select
                                        className="withdraw-select"
                                        value={t('balances.withdraw')}
                                        onChange={(value, name) => selectOnChange(
                                            value,
                                            name,
                                            coin.handle,
                                            amount,
                                        )
                                        }
                                    >
                                        <Option name="CRYPTO_WITHDRAW" value="CRYPTO_WITHDRAW">
                                            Crypto
                                        </Option>
                                        {isHideFiatDepositAndWithdrawal
                                            ? (
                                                <Option name="FIAT_WITHDRAW" value="FIAT_WITHDRAW">
                                                    Fiat
                                                </Option>
                                            )
                                            : null}
                                    </Select>
                                    <Button
                                        type="button"
                                        className={style.walletTable__tradeBtn}
                                        onClick={() => trade(coin)}
                                    >
                                        {t('balances.trade')}
                                    </Button>
                                </div>
                            </div>
                        </Fragment>
                    );
                })}
            </div>

            <Modal
                isOpen={openModalState}
                onRequestClose={closeModal}
                ariaHideApp={false}
                className={modalContainerStyle}
                contentLabel="Example Modal"
                overlayClassName="modalOverlay"
            >
                <Fragment>
                    {modalLoading ? <Spin size="large" /> : null}
                    <div className={style.modal} style={loadingStyle}>
                        {depositModal ? (
                            <Fragment>
                                <h2 className={style.modal__title}>
                                    {t('balances.deposit')}
                                </h2>
                                <div onClick={closeModal}>
                                    <img
                                        src={close}
                                        className={style.modal__close}
                                        alt="closeModal"
                                    />
                                </div>
                                <form>
                                    <div className="modal__select">
                                        <p className="filterBlock-select__title">
                                            {t('general.currency')}
                                        </p>
                                        <Select
                                            className="filterBlock-select"
                                            placeholder={t('general.choose')}
                                            onChange={chooseCurrency}
                                            value={selectedDepositCoin}
                                        >
                                            {allCoins.map(items => {
                                                const { handle, name } = items;
                                                return (
                                                    <Option
                                                        key={handle}
                                                        name="coin"
                                                        value={handle}
                                                    >
                                                        {name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </div>

                                    {getDeposit.slice(0, 1).map(items => {
                                        const {
                                            id,
                                            userCoinAddress: {
                                                address,
                                                wallet: edcWallet,
                                                coin: {
                                                    commission: {
                                                        depositPercentage,
                                                        minimalDepositValue,
                                                    },
                                                    handle,
                                                },
                                            },
                                        } = items;

                                        const newAddress = getNewAdress
                                            .filter(item => item.generatedAdressId === id)
                                            .reverse();

                                        const currentGeneratedAddress = newAddress.length > 0
                                            ? newAddress[0].generatedAdress
                                            : null;

                                        return (
                                            <Fragment key={address}>
                                                {!isEDCorSFC.includes(
                                                    selectedDepositCoin,
                                                ) ? (
                                                        <div
                                                            className={
                                                                style.modal__inputWrapper
                                                            }
                                                        >
                                                            <Field
                                                                id="wallet"
                                                                type="text"
                                                                name="wallet"
                                                                value={
                                                                    currentGeneratedAddress
                                                                || address
                                                                }
                                                                inputStyle={
                                                                    style.modal__inputWrapper_input
                                                                }
                                                                labelText={t(
                                                                    'general.wallet',
                                                                )}
                                                                labelStyle={
                                                                    style.modal__inputWrapper_label
                                                                }
                                                            />
                                                            <CopyToClipboard
                                                                text={
                                                                    currentGeneratedAddress
                                                                || address
                                                                }
                                                                onCopy={successfulCopied}
                                                            >
                                                                <img
                                                                    className={
                                                                        style.modal__copyContent
                                                                    }
                                                                    src={copyContent}
                                                                    alt="copyContent"
                                                                />
                                                            </CopyToClipboard>
                                                        </div>
                                                    ) : null}
                                                {isEDCorSFC.includes(
                                                    selectedDepositCoin,
                                                ) ? (
                                                        <Fragment>
                                                            <div
                                                                className={
                                                                    style.modal__inputWrapper
                                                                }
                                                            >
                                                                <Field
                                                                    id="wallet"
                                                                    type="text"
                                                                    name="wallet"
                                                                    value={
                                                                        currentGeneratedAddress
                                                                    || edcWallet
                                                                    }
                                                                    inputStyle={
                                                                        style.modal__inputWrapper_input
                                                                    }
                                                                    labelText={t(
                                                                        'general.wallet',
                                                                    )}
                                                                    labelStyle={
                                                                        style.modal__inputWrapper_label
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                className={
                                                                    style.modal__inputWrapper
                                                                }
                                                            >
                                                                <Field
                                                                    id="memo"
                                                                    type="text"
                                                                    name="memo"
                                                                    value={
                                                                        currentGeneratedAddress
                                                                    || address
                                                                    }
                                                                    inputStyle={
                                                                        style.modal__inputWrapper_input
                                                                    }
                                                                    labelText="Memo"
                                                                    labelStyle={
                                                                        style.modal__inputWrapper_label
                                                                    }
                                                                />
                                                                <CopyToClipboard
                                                                    text={
                                                                        currentGeneratedAddress
                                                                    || address
                                                                    }
                                                                    onCopy={successfulCopied}
                                                                >
                                                                    <img
                                                                        className={
                                                                            style.modal__copyContent
                                                                        }
                                                                        src={copyContent}
                                                                        alt="copyContent"
                                                                    />
                                                                </CopyToClipboard>
                                                            </div>
                                                        </Fragment>
                                                    ) : null}
                                                <p className={style.modal__depositFee}>
                                                    {t('balances.depositFee', {
                                                        count: depositPercentage,
                                                        currency: selectedDepositCoin,
                                                    })}
                                                </p>
                                                <p
                                                    className={
                                                        style.modal__depositWarning
                                                    }
                                                >
                                                    {t('balances.depositWarning', {
                                                        count: minimalDepositValue,
                                                    })}
                                                </p>
                                                {selectedDepositCoin === 'ETH' ? (
                                                    <p
                                                        className={
                                                            style.modal__depositFee
                                                        }
                                                    >
                                                        {t('balances.ethDepositWarning')}
                                                    </p>
                                                ) : null}
                                                <div className="modal__qrCode">
                                                    <QRCode
                                                        value={
                                                            currentGeneratedAddress
                                                            || address
                                                        }
                                                    />
                                                </div>
                                                {!isEDCorSFC.includes(
                                                    selectedDepositCoin,
                                                ) ? (
                                                        <div
                                                            className={
                                                                style.modal__buttonWrapper
                                                            }
                                                        >
                                                            <Button
                                                                id="generateNewAdress"
                                                                type="button"
                                                                disabled={spinNewAdress}
                                                                className={
                                                                    style.modal__depositButton
                                                                }
                                                                onClick={() => generateNewAdress(handle)
                                                                }
                                                            >
                                                                {t(
                                                                    'balances.generateNewAdress',
                                                                )}
                                                                <Icon
                                                                    type="sync"
                                                                    spin={spinNewAdress}
                                                                />
                                                            </Button>
                                                        </div>
                                                    ) : null}
                                            </Fragment>
                                        );
                                    })}
                                </form>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <h2 className={style.modal__title}>
                                    {t('balances.withdraw')}
                                </h2>
                                <div onClick={closeModal}>
                                    <img
                                        src={close}
                                        className={style.modal__close}
                                        alt="closeModal"
                                    />
                                </div>

                                <form onSubmit={withdrawOnSubmit}>
                                    <div className="modal__select">
                                        <p className="filterBlock-select__title">
                                            {t('general.currency')}
                                        </p>
                                        <Select
                                            className="filterBlock-select"
                                            placeholder={t('general.choose')}
                                            onChange={chooseWithDrawCoin}
                                            value={selectedWithDrawCoin}
                                        >
                                            {allCoins.map(items => {
                                                const { handle, name } = items;
                                                return (
                                                    <Option
                                                        key={handle}
                                                        name="coin"
                                                        value={handle}
                                                    >
                                                        {name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </div>
                                    <div className={style.modal__inputWrapper}>
                                        <Field
                                            id="availableBalance"
                                            type="text"
                                            name="availableBalance"
                                            value={selectedWithDrawAmomount}
                                            inputStyle={availableBalanceStyle}
                                            labelText={t('balances.availableBalance')}
                                            labelStyle={style.modal__inputWrapper_label}
                                            disabled
                                        />
                                    </div>
                                    <p className={style.modal__minimalSumToWithdraw}>
                                        {t('balances.minimumWithdrawalAmount', {
                                            amount: minimumWithdrawalAmount,
                                        })}
                                    </p>

                                    <div className={style.modal__inputWrapper}>
                                        <Field
                                            id="amount"
                                            type="number"
                                            name="amount"
                                            value={amountState}
                                            placeholder={t('general.typeHere')}
                                            onChange={withdrawInputOnchange}
                                            inputStyle={style.modal__inputWrapper_input}
                                            labelText={t('balances.amountToWithdraw')}
                                            labelStyle={style.modal__inputWrapper_label}
                                            error={amountError}
                                            step="any"
                                        />
                                    </div>
                                    <div className={style.modal__inputWrapper}>
                                        <Field
                                            id="toAddress"
                                            type="text"
                                            name="toAddress"
                                            value={toAddress}
                                            placeholder={t('general.typeHere')}
                                            onChange={withdrawInputOnchange}
                                            inputStyle={style.modal__inputWrapper_input}
                                            labelText={t('general.wallet')}
                                            labelStyle={style.modal__inputWrapper_label}
                                            error={toAddressError}
                                        />
                                    </div>
                                    {isEDCorSFC.includes(selectedWithDrawCoin) ? (
                                        <p className={style.modal__minimalSumToWithdraw}>
                                            Withdraw only to your personal account. Please
                                            note: withdrawals to exchanges are not
                                            supported if they require memo.
                                        </p>
                                    ) : null}
                                    <div className={style.modal__buttonWrapper}>
                                        {wallet
                                            .filter(
                                                item => item.coin.handle === withDrawCoin,
                                            )
                                            .map(item => {
                                                const {
                                                    id,
                                                    coin: {
                                                        commission: {
                                                            withdrawPercentage,
                                                            minWithdrawValueToFixedCommission,
                                                            fixedWithdrawValueCommission,
                                                        },
                                                    },
                                                } = item;

                                                const isCoinEdc = isEDCorSFC.includes(
                                                    selectedWithDrawCoin,
                                                )
                                                    ? 5
                                                    : 8;

                                                let withdrawComission = '';
                                                if (
                                                    amountState
                                                    <= minWithdrawValueToFixedCommission
                                                ) {
                                                    withdrawComission = fixedWithdrawValueCommission;
                                                } else {
                                                    const getComissionPercent = (amountState
                                                            * withdrawPercentage)
                                                        / 100;
                                                    const trimPercentCommission = parseFloat(
                                                        getComissionPercent,
                                                    ).toFixed(isCoinEdc);
                                                    withdrawComission = trimPercentCommission.replace(
                                                        /0*$/,
                                                        '',
                                                    );
                                                }
                                                return (
                                                    <p
                                                        key={id}
                                                        className={
                                                            style.modal__withdrawFee
                                                        }
                                                    >
                                                        {t('balances.withdrawFeeCount', {
                                                            count: +withdrawComission,
                                                        })}{' '}
                                                        {selectedWithDrawCoin}
                                                    </p>
                                                );
                                            })}

                                        <Button
                                            id="login-button"
                                            type="submit"
                                            className={style.modal__withdrawButton}
                                            disabled={isDisabledWithdrawSubmitBtn}
                                        >
                                            {t('balances.withdrawFunds')}
                                        </Button>
                                    </div>
                                </form>
                            </Fragment>
                        )}
                    </div>
                </Fragment>
            </Modal>
            <Modal
                isOpen={isShowFiatDepositModal}
                onRequestClose={closeModal}
                ariaHideApp={false}
                className="tradeModalWindow reactModal depositFiatModal"
                contentLabel="Example Modal"
                overlayClassName="modalOverlay"
            >
                <div className={style.modal}>
                    <h2 className={style.modal__title}>{t('balances.deposit')} Fiat</h2>
                    <div onClick={closeModal}>
                        <img
                            src={close}
                            className={style.modal__close}
                            alt="closeModal"
                        />
                    </div>
                    <div>
                        <div className="modal__select">
                            <p className="filterBlock-select__title">
                                {t('general.currency')}
                            </p>
                            <div className={style.fiatCoinWrapperSelect}>
                                <Select
                                    className="filterBlock-select fiatCoinSelect"
                                    placeholder={t('general.choose')}
                                    onChange={chooseCurrency}
                                    value={fiatCoin || undefined}
                                >
                                    {filteredFiatCoin.map(item => (
                                        <Option key={item} name="fiatCoin" value={item}>
                                            {t(`coins.${item}`)}
                                        </Option>
                                    )) }
                                </Select>
                                {fiatCoinError ? (
                                    <div className={style.inputWrapper__invalid}>
                                        {fiatCoinError}
                                    </div>
                                ) : null}
                            </div>
                            <div className={style.modal__inputWrapper}>
                                <Field
                                    id="fiatAmount"
                                    type="text"
                                    name="fiatAmount"
                                    value={fiatAmount}
                                    placeholder={t('general.typeHere')}
                                    onChange={inputOnChange}
                                    inputStyle={fiatAmountInputStyle}
                                    labelText={t('general.amount')}
                                    labelStyle={style.modal__inputWrapper_label}
                                    error={fiatAmountError}
                                />
                                <p className={style.modal__inputWrapper_fiatTicker}>{fiatCoin}</p>
                            </div>
                            <div className={style.modal__buttonWrapper}>
                                <Button
                                    type="button"
                                    className={style.walletTable__depositFiatBtn}
                                    onClick={submitFiatDeposit}
                                    disabled={disabledSubmitBtn}
                                >
                                    {t('balances.deposit')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={isShowFiatWithdrawalModal}
                onRequestClose={closeModal}
                ariaHideApp={false}
                className="tradeModalWindow reactModal depositFiatModal"
                contentLabel="Example Modal"
                overlayClassName="modalOverlay"
            >
                <div className={style.modal}>
                    <h2 className={style.modal__title}>{t('balances.withdraw')} Fiat</h2>
                    <div onClick={closeModal}>
                        <img
                            src={close}
                            className={style.modal__close}
                            alt="closeModal"
                        />
                    </div>
                    <div>
                        <div className="modal__select">
                            <p className="filterBlock-select__title">
                                {t('general.currency')}
                            </p>
                            <div className={style.fiatCoinWrapperSelect}>
                                <Select
                                    className="filterBlock-select fiatCoinSelect"
                                    placeholder={t('general.choose')}
                                    onChange={chooseCurrency}
                                    value={fiatCoin || undefined}
                                >
                                    {filteredFiatCoin.map(item => (
                                        <Option key={item} name="fiatCoin" value={item}>
                                            {t(`coins.${item}`)}
                                        </Option>
                                    )) }
                                </Select>
                                {fiatCoinError ? (
                                    <div className={style.inputWrapper__invalid}>
                                        {fiatCoinError}
                                    </div>
                                ) : null}
                            </div>
                            <div className={style.modal__inputWrapper}>
                                <Field
                                    id="fiatAmount"
                                    type="text"
                                    name="fiatAmount"
                                    value={fiatAmount}
                                    placeholder={t('general.typeHere')}
                                    onChange={inputOnChange}
                                    inputStyle={fiatAmountInputStyle}
                                    labelText={t('general.amount')}
                                    labelStyle={style.modal__inputWrapper_label}
                                    error={fiatAmountError}
                                />
                                <p className={style.modal__inputWrapper_fiatTicker}>{cryptoCoinHandle}</p>
                            </div>
                            <div className={style.modal__inputWrapper}>
                                <Field
                                    id="totalPriceInFiat"
                                    type="text"
                                    name="totalPriceInFiat"
                                    value={totalPriceInFiat}
                                    placeholder={t('general.typeHere')}
                                    onChange={inputOnChange}
                                    inputStyle={fiatAmountInputStyle}
                                    labelText={t('general.total')}
                                    labelStyle={style.modal__inputWrapper_label}
                                />
                                <p className={style.modal__inputWrapper_fiatTicker}>{fiatCoin}</p>
                            </div>
                            <div className={style.modal__inputWrapper}>
                                <Field
                                    id="cardNumber"
                                    type="text"
                                    placeholder={t('general.typeHere')}
                                    name="cardNumber"
                                    value={cardNumber}
                                    onChange={inputOnChange}
                                    inputStyle={fiatAmountInputStyle}
                                    error={cardNumberError}
                                    labelStyle={style.modal__inputWrapper_label}
                                    labelText={t(
                                        'newOrder.placeHolderCardNumber',
                                    )}
                                />
                            </div>
                            <div className={style.modal__buttonWrapper}>
                                <Button
                                    type="button"
                                    className={style.walletTable__withdrawFiatBtn}
                                    onClick={submitFiatWithdraw}
                                    disabled={disabledSubmitBtn}
                                >
                                    {t('balances.withdraw')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

WalletView.defaultProps = {
    wallet: [],
    allCoins: [],
    getDeposit: [],
    filteredFiatCoin: [],
    cryptoLimits: [],
    openModalState: false,
    isShowFiatDepositModal: false,
    isShowFiatWithdrawalModal: false,
    depositModal: false,
    modalLoading: false,
    spinNewAdress: false,
    isDisabledWithdrawSubmitBtn: false,
    disabledSubmitBtn: false,
    successfulCopied: () => {},
    withdrawOnSubmit: () => {},
    chooseCurrency: () => {},
    chooseWithDrawCoin: () => {},
    withdrawInputOnchange: () => {},
    generateNewAdress: () => {},
    closeModal: () => {},
    trade: () => {},
    selectOnChange: () => {},
    inputOnChange: () => {},
    submitFiatDeposit: () => {},
    submitFiatWithdraw: () => {},
    fiatAmount: '',
    totalPriceInFiat: '',
    selectedDepositCoin: '',
    withDrawCoin: '',
    selectedWithDrawCoin: '',
    selectedWithDrawAmomount: '',
    amount: '',
    toAddress: '',
    amountError: '',
    toAddressError: '',
    fiatCoin: '',
    fiatCoinError: '',
    fiatAmountError: '',
    cryptoCoinHandle: '',
    cardNumber: '',
    cardNumberError: '',
    getNewAdress: [],
    totalBalanceRef: {},
    totalBalanceTitleRef: {},
    availableBalanceBalanceRef: {},
    availableBalanceBalanceTitleRef: {},
    filterCurrentCoinData: {},
};

WalletView.propTypes = {
    wallet: PropTypes.arrayOf(PropTypes.object),
    allCoins: PropTypes.arrayOf(PropTypes.object),
    getDeposit: PropTypes.arrayOf(PropTypes.object),
    cryptoLimits: PropTypes.arrayOf(PropTypes.object),
    filteredFiatCoin: PropTypes.any,
    openModalState: PropTypes.bool,
    isShowFiatDepositModal: PropTypes.bool,
    isShowFiatWithdrawalModal: PropTypes.bool,
    isDisabledWithdrawSubmitBtn: PropTypes.bool,
    disabledSubmitBtn: PropTypes.bool,
    spinNewAdress: PropTypes.bool,
    depositModal: PropTypes.bool,
    modalLoading: PropTypes.bool,
    successfulCopied: PropTypes.func,
    withdrawOnSubmit: PropTypes.func,
    chooseCurrency: PropTypes.func,
    withdrawInputOnchange: PropTypes.func,
    chooseWithDrawCoin: PropTypes.func,
    generateNewAdress: PropTypes.func,
    closeModal: PropTypes.func,
    trade: PropTypes.func,
    selectOnChange: PropTypes.func,
    inputOnChange: PropTypes.func,
    submitFiatDeposit: PropTypes.func,
    submitFiatWithdraw: PropTypes.func,
    fiatAmount: PropTypes.string,
    totalPriceInFiat: PropTypes.string,
    withDrawCoin: PropTypes.string,
    getNewAdress: PropTypes.arrayOf(PropTypes.object),
    selectedDepositCoin: PropTypes.string,
    selectedWithDrawCoin: PropTypes.string,
    selectedWithDrawAmomount: PropTypes.string,
    amount: PropTypes.any,
    toAddress: PropTypes.string,
    amountError: PropTypes.string,
    toAddressError: PropTypes.string,
    fiatCoin: PropTypes.string,
    fiatCoinError: PropTypes.string,
    fiatAmountError: PropTypes.string,
    cryptoCoinHandle: PropTypes.string,
    cardNumber: PropTypes.string,
    cardNumberError: PropTypes.string,
    totalBalanceRef: PropTypes.object,
    totalBalanceTitleRef: PropTypes.object,
    availableBalanceBalanceRef: PropTypes.object,
    availableBalanceBalanceTitleRef: PropTypes.object,
    filterCurrentCoinData: PropTypes.object,
};

export default WalletView;
