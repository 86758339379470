import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import GetService from '../../../../../services/get-service';
import withGetService from '../../../../hoc';
import { modalTradeActions, FilterActions } from '../../../../../actions';
import getAllCoinsAction from '../../../../../actions/getAllCoins.actions';
import { compose } from '../../../../../utils';
import CryptemperView from './cryptemper-page-view';
import ErrorIndicator from '../../../error-page/error-indicator';
import getAllInvoicesAction from '../../../../../actions/getAllInvoices.action';

export class CryptemperContainer extends Component {
    getService = new GetService();

    state = {
        cardList: [],
    };

    componentDidMount() {
        const { userCoutry, filterParams, putLocationToFilter } = this.props;
        if (userCoutry.value) {
            const filterParamsData = {
                ...filterParams,
                country: userCoutry.value,
            };
            putLocationToFilter(filterParamsData);
        }
        this.loadAllCards();

        if (Object.keys(filterParams).length > 0) {
            this.filterParamsToQuery();
        }
    }

    componentDidUpdate(prevProps) {
        const { filterParams } = this.props;
        if (JSON.stringify(filterParams) !== JSON.stringify(prevProps.filterParams) && filterParams !== prevProps.filterParams) {
            this.filterParamsToQuery();
        }
    }

    filterParamsToQuery = () => {
        const { filterParams, fetchAllOrders = () => {}, getAllCoins = () => {} } = this.props;
        const params = Object.keys(filterParams)
            .map(
                keyParams => `${encodeURIComponent(keyParams)}=${encodeURIComponent(
                    filterParams[keyParams],
                )}`,
            )
            .join('&');
        localStorage.setItem('ordersStorageFilter', params);
        const ordersStorageFilter = localStorage.getItem('ordersStorageFilter');
        fetchAllOrders(params || ordersStorageFilter);
        getAllCoins();
    };


    clearOrdersFilter = () => {
        localStorage.removeItem('ordersStorageFilter');
    };

    loadAllCards = () => {
        this.getService
            .getUserCard()
            .then(cardList => {
                this.setState({
                    cardList,
                });
            })
            .catch(this.onError);
    };

    render() {
        const {
            orders, loading, error, openTrade,
        } = this.props;

        const { cardList } = this.state;

        const hasData = !(error);

        const errorMessage = error ? <ErrorIndicator /> : null;

        const content = hasData ? (
            <CryptemperView
                loading={loading}
                orders={orders}
                openTrade={id => openTrade(id)}
                changePaginationPage={this.changePaginationPage}
                cardList={cardList}
                refreshOrders={this.filterParamsToQuery}
            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}

                {content}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        modalTrade: { modalTrade },
        currentFilter: { filterParams },
        getAllInvoices: { orders = null, error, loading },
        getUserCountry: { country: userCoutry },
    } = state;

    return {
        modalTrade,
        filterParams,
        orders,
        error,
        loading,
        userCoutry,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        fetchAllOrders: getAllInvoicesAction(getService),
        getAllCoins: getAllCoinsAction(getService),
        openTrade: id => modalTradeActions.openModalTrade(id),
        putLocationToFilter: filterParamsData => FilterActions.selectFilter(filterParamsData),
    },
    dispatch,
);

CryptemperContainer.defaultProps = {
    fetchAllOrders: () => {},
    getAllCoins: () => {},
    openTrade: () => {},
    putLocationToFilter: () => {},
    filterParams: {},
    userCoutry: {},
    orders: {},
    error: false,
    loading: true,
};

CryptemperContainer.propTypes = {
    fetchAllOrders: PropTypes.func,
    getAllCoins: PropTypes.func,
    openTrade: PropTypes.func,
    putLocationToFilter: PropTypes.func,
    filterParams: PropTypes.object,
    orders: PropTypes.object,
    error: PropTypes.bool,
    loading: PropTypes.bool,
    userCoutry: PropTypes.object,
};

export default compose(
    withGetService(),
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(CryptemperContainer);
