import invoicesConstans from '../constants/invoices.constants';

const getAllInvoicesRequested = () => ({
    type: invoicesConstans.FETCH_ALL_INVOICES_REQUEST,
});

const getAllInvoicesLoaded = data => ({
    type: invoicesConstans.FETCH_ALL_INVOICES_SUCCESS,
    payload: data,
});

const getAllInvoicesError = error => ({
    type: invoicesConstans.FETCH_ALL_INVOICES_FAILURE,
    payload: error,
});

const getAllInvoicesAction = getService => params => dispatch => {
    dispatch(getAllInvoicesRequested());
    getService
        .getAllInvoices(params)
        .then(data => dispatch(getAllInvoicesLoaded(data)))
        .catch(err => dispatch(getAllInvoicesError(err)));
};

export default getAllInvoicesAction;
